<template>
  <app-card-wizard title="E-posta Doğrulama">
    <template v-slot:description v-if="!isCodeSent">
      <p v-if="fromEdevlet" style="font-weight:600">
        Merhaba {{ this.applicationData.applicant.fullName }}
      </p>
      <p>
        Başvurunuza başlamadan önce başvuru sürecinin daha sağlıklı
        ilerleyebilmesi için e-posta adresinizi doğrulamamız gerekiyor. Bunun
        için lütfen önce e-posta adresini belirtin. Belirttiğiniz e-posta
        adresiniz aynı zamanda üyelik sistemine kaydedilecek e-posta adresi
        olacaktır.
      </p>
    </template>
    <div v-if="isCodeSent">
      <app-value-outlet
        label="E-posta adresi"
        :value="email"
      ></app-value-outlet>
      <p>
        Yukarıdaki adrese gönderilen doğrulama kodunu belirtilen süre içerisinde
        aşağıdaki kutuya yazın. Eğer gelen kutunuzda göremiyorsanız
        <span class="text-danger text-medium">önemsiz/spam/junk</span>
        klasörlerinizi kontrol etmeyi unutmayın.
      </p>

      <app-form-group
        name="verificationCode"
        label="Doğrulama kodu"
        :validator="validator"
      >
        <div class="input-group">
          <input
            name="verificationCode"
            class="form-control"
            v-model.trim="verificationCode"
            placeholder="Lütfen e-posta adresinize gönderilen doğrulama kodunu girin"
          />
          <div class="input-group-append">
            <count-down
              v-if="counting"
              :time="4 * 60 * 1000"
              v-slot:default="{ time }"
              class="input-group-text"
              @end="countDownEnd"
            >
              {{ time.totalSeconds }}
            </count-down>
          </div>
        </div>
      </app-form-group>
      <app-wizard-buttons
        v-bind:next-button-disabled="isLoading"
        :next-button-is-loading="isLoading"
        next-button-text="Doğrula"
        @next-button-click="validateCode"
        :prev-button-visible="false"
      ></app-wizard-buttons>
    </div>
    <div v-else>
      <app-form-group
        :validator="validator"
        name="email"
        label="E-posta adresi"
        help="Belirteceğiniz e-posta adresi başvuru süreci ve sonrasında ki e-posta bildirimlerinin gönderileceği e-posta hesabı olacaktır. Bu nedenle sıkça kullandığınız geçerli bir e-posta adresi belirtin."
      >
        <input
          name="email"
          class="form-control"
          placeholder="E-posta adresinizi belirtin"
          maxlength="200"
          v-model.trim.lazy="email"
          @change="onEmailChange"
        />
      </app-form-group>
      <div class="alert alert-info small">
        <div>
          Sonraki adımlarda şu anda belirteceğiniz e-posta adresine ek olarak,
          cep numaranızı ve vergi numarasını (Şahıs şirketleri için Kimlik
          fotokopinizi) sistemimize tanımlamanız gerekmektedir. Kullanacağınız
          e-posta adresi kurumsal bir e-posta adresi ise (bilgi@.., info@..,
          satis@.. vs) KVKK kapsamında herhangi bir işlem söz konusu değildir.
          Fakat isminiz.soyisminiz@... Benzeri kişisel bir e-posta adresi ile
          kayıt yapacaksanız
          <a href="https://tim.org.tr/tr/kvkk-aydinlatma-metni" target="_blank"
            >aydınlatma beyanımızı</a
          >
          incelemelisiniz. TİM, Kişisel Veri Koruma mevzuatı ve bilgi güvenliği
          standartlarına uygun hareket etmektedir. Arzu ederseniz
          <a
            href="https://tim.org.tr/tr/bilgi-guvenligi-politikasi"
            target="_blank"
            >bilgi güvenliği politikamıza</a
          >
          da göz atabilirsiniz.
        </div>
      </div>
      <app-wizard-buttons
        v-bind:next-button-disabled="isLoading"
        :next-button-is-loading="isLoading"
        next-button-text="Doğrulama Kodunu Gönder"
        @next-button-click="sendVerificationCode"
        :prev-button-visible="false"
      ></app-wizard-buttons>
    </div>
  </app-card-wizard>
</template>

<script>
import { mapActions } from "vuex";
import { email, required } from "@vuelidate/validators";
import CountDown from "../components/CountDown.vue";
import AppValueOutlet from "../components/ValueOutlet.vue";
import { local, makeId, settings, decodeBase64 } from "../shared";

import apiClient from "../services";

export default {
  components: {
    CountDown,
    AppValueOutlet
  },
  data() {
    return {
      email: null,
      verificationCode: null,
      counting: false,
      isCodeSent: false,
      isLoading: false
    };
  },
  validations: {
    email: {
      required,
      email
    },
    verificationCode: {
      required
    }
  },
  methods: {
    onEmailChange(e) {
      this.updateApplicationData({ email: e.target.value });
    },
    countDownEnd() {
      this.isCodeSent = false;
      this.counting = false;
    },
    showError() {},
    sendVerificationCode() {
      this.validator.email.$touch();

      if (this.validator.email.$invalid) {
        this.scrollToError();
        return;
      }

      this.isLoading = true;

      apiClient
        .sendValidationCode(this.email)
        .then((response) => {
          if (response && response.success) {
            this.isCodeSent = true;
            this.counting = true;
          } else {
            throw new Error(response);
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Doğrulama Kodu Gönderilemedi",
            html:
              "Doğrulama kodu gönderilirken bir hata oluştu. Doğru e-posta adresi girdiğinizden emin olup tekrar deneyin.",
            confirmButtonText: "Tamam"
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    validateCode() {
      this.validator.$touch();
      if (this.validator.verificationCode.$invalid) return;

      this.isLoading = true;

      apiClient
        .validateEmailAndCode(this.email, this.verificationCode)
        .then((response) => {
          if (response && response.success) {
            this.updateApplicationData({
              email: this.email,
              isEmailValidated: true
            });

            local.token = response.data.token;
            local.refreshToken = response.data.refreshToken;

            this.markStepAsCompleted(this.stepName);

            this.goTo("/check-membership/" + this.applicationData.stateId);
          } else {
            throw new Error(response);
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Geçersiz Doğrulama Kodu",
            html:
              "Geçersiz ya da hatalı doğrulama kodu girdiniz. Lütfen kontrol edip tekrar deneyin.",
            confirmButtonText: "Tamam"
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    startFromEdevlet(userData) {
      this.isLoading = true;
      this.setRegistrationType(settings.registrationTypes.exporter.name);
      const stateId = makeId(128);

      this.updateApplicationData({ stateId });

      try {
        const data = JSON.parse(decodeBase64(userData));

        if (!!data.identityNumber && !!data.fullName) {
          const applicant = {
            fullName: data.fullName,
            identityNumber: data.identityNumber,
            fromEdevlet: true
          };

          this.updateApplicationData({ applicant });
        }
      } catch (ex) {
        console.error(ex);
      }

      this.isLoading = false;

      this.goTo(`/validation/${stateId}`);
    },
    ...mapActions(["setRegistrationType"])
  },
  computed: {
    validator() {
      return this.$v;
    },
    stepName() {
      return "validation";
    },
    fromEdevlet() {
      return this.applicationData?.applicant?.fromEdevlet || false;
    }
  },
  beforeMount() {
    if (this.$route.name === "edevlet-login" && this.$route.params.userData) {
      this.startFromEdevlet(this.$route.params.userData);
    }

    this.markStepAsInCompleted(this.stepName);

    const appData = this.applicationData || {};
    this.email = appData.email;
  }
};
</script>

<style></style>
